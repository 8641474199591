<template>
  <section class="m-setting-clause m-scroll">
    <!-- 头部导航 -->
    <m-header title="条款" ></m-header>
    <div class="main">
        <p>
            配送条款
        </p>
        <h1>
            <span>MC INTERNATIONAL ENTERPRISES LTD.</span>
        </h1>
        <h1>
            <span>MC</span><span>国际企业有限公司</span>
        </h1>
        <p>
            <span>最后更新日期：2018年9月7日</span>
        </p>
        <p class="t-a-l">
            <span>感谢您在MoobyYoho.com上的访问和购物。以下是我们的配送条款。</span>
        </p>
        <p class="t-a-l">
            <span>Shipment processing time</span><span>发货时间</span>
        </p>
        <p class="t-a-l">
            <span>所有订单在1-2个工作日内处理.周日和节假日将不会进行发货。</span>
        </p>
        <p class="t-a-l">
            <span>如果订单数量巨大，发货时间可能出现延迟。超出的额外天数给您带来不便，请您谅解。如果您的订单有重大延误，我们将通过电子邮件或电话与您联系。</span>
        </p>
        <p class="t-a-l">
            <span>&nbsp;</span>
        </p>
        <p class="t-a-l">
            <strong><span>运费和配送时效</span></strong>
        </p>
        <p class="t-a-l">
            <span>我们提供免费送货服务，下单后，大温地区您的订单将于3个工作日内到达。</span>
        </p>
        <p class="t-a-l">
            <span>其他地区的订单将于7个工作日内到底，除不可抗因素除外。</span>
        </p>
        <p class="t-a-l">
            <strong><span>注：交货延迟可能偶尔发生。</span></strong>
        </p>
        <p class="t-a-l">
            <strong><span>&nbsp;</span></strong>
        </p>
        <p class="t-a-l">
            <strong><span>确认发货和物流详情</span></strong>
        </p>
        <p class="t-a-l">
            <span>您可以在物流中心页面看到您的订单状态和物流详情，物流详情将在发货后24小时内更新。</span>
        </p>
        <p class="t-a-l">
            <span>&nbsp;</span>
        </p>
        <p class="t-a-l">
            <strong><span>损失</span></strong>
        </p>
        <p class="t-a-l">
            <span>Mooby Yoho</span><span>不对任何在运输过程中损坏或丢失的产品负责。如果您收到的商品发生破损，请联系装运承运人提出索赔。</span>
        </p>
        <p>
            <span>在提出索赔前，请保留损坏的商品及其所有包装材料。</span>
        </p>
        <p class="t-a-l">
            <span>&nbsp;</span>
        </p>
    </div>
  </section>
</template>

<script>
import MHeader  from '@/components/zh/m-header.vue'

export default {
  name:'Clause',
  components:{ MHeader },
  data(){
    return {
      
    }
  }
}
</script>

<style lang="less" scoped>
.m-setting-clause {
  width: 100%;
  height: 100%;
  background-color: #F5F9FF;
  padding-top:44px;
  overflow-y: auto;
  .main {
    margin-top: 12px;
    width: 100%;
    padding: 0 16px;
    font-size: 16px;
    >p:nth-child(1){font-size:19px;text-align: center;font-size: 30px;margin-bottom:50px;font-weight: 600;}
    >p:nth-child(4){margin-top:27px;margin-right:0;margin-bottom:8px;margin-left:0;text-align:left}
    >p:nth-child(9) span{font-size:16px;color:black;background:white}
    .t-a-l {text-align:left}
  }
}
</style>